import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { title as defaultTitle } from "../../config/website"

type SEOProps = {
  description?: string
  lang?: string
  meta?: any
  keywords?: any
  title: string
  pathname?: string
  banner?: string
  isPost?: boolean
  firstPublicationDate?: string
}

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
  pathname,
  banner,
  isPost,
  firstPublicationDate,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            title
            siteUrl
            description
            banner
            headline
            siteLanguage
            ogLanguage
            author
            twitter
            facebook
          }
        }
      }
    `
  )

  const {
    siteMetadata: { siteUrl, author, headline, siteLanguage },
  } = site

  const seo = {
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    image: `${siteUrl}${banner || site.siteMetadata.banner}`,
    url: `${siteUrl}${pathname || ""}`,
  }

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: site.siteMetadata.description,
    name: site.siteMetadata.title,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    datePublished: "2020-01-21T10:30:00+01:00",
    dateModified: site.buildTime,
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${site.siteMetadata.banner}`,
    },
  }

  let schemaPost
  let titleTemplate

  if (isPost) {
    schemaPost = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2020",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${site.siteMetadata.banner}`,
        },
      },
      datePublished: firstPublicationDate,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      name: `${defaultTitle | seo.title}`,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }

    titleTemplate = `${defaultTitle} | %s`
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          name: `image`,
          content: seo.image,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {!isPost && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {isPost && (
        <script type="application/ld+json">{JSON.stringify(schemaPost)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `ru-UA`,
  meta: [],
  keywords: [],
  description: ``,
}

export default SEO
