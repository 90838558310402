module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Блог по ReactJS: статьи, уроки, примеры, основы, tutorial, docs ⚛️ Frontend онлайн-курсы - ReactWarriors", // Navigation and Site Title
  titleAlt: "Блог по ReactJS: статьи, уроки, примеры, основы, tutorial, docs ⚛️ Frontend онлайн-курсы - ReactWarriors", // Title for JSONLD
  description:
    "Блог про React JavaScript : статьи, уроки, примеры, основы, tutorial, документация ⚛️ Онлайн-курсы по ReactJS - ReactWarriors | Frontend курс и видеоуроки",
  headline: "Блог по ReactJS: статьи, уроки, примеры, основы, tutorial, docs ⚛️ Frontend онлайн-курсы - ReactWarriors", // Headline for schema.org JSONLD
  url: "https://blog.reactwarriors.com", // Domain of your site. No trailing slash!
  siteLanguage: "ru-UA", // Language Tag on <html> element
  logo: "/images/logo.jpg", // Used for SEO
  ogLanguage: "ru_UA", // Facebook Language

  // JSONLD / Manifest
  favicon: "static/favicon.png", // Used for manifest favicon generation
  shortName: "ReactWarriors", // shortname for manifest. MUST be shorter than 12 characters
  author: "Евгений Подгаецкий", // Author for schemaORGJSONLD
  themeColor: "#f26120",
  backgroundColor: "#ffffff",

  twitter: "@epodgaetskiy", // Twitter Username
  facebook: "reactwarriors", // Facebook Site Name
  // googleAnalyticsID: "UA-47519312-7",
  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
}
